<template>
  <menu-header title="Add Menu" :add="false"></menu-header>
  <main>
    <div class="max-w-7xl mx-auto px-4">
      <!-- Content -->
      <form class="space-y-8 divide-y divide-gray-200" v-on:submit.prevent>
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Store
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="menu.name"
                    required
                    type="text"
                    name="name"
                    id="name"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="siteId"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Site ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="menu.siteId"
                    required
                    type="number"
                    name="siteId"
                    id="siteId"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="menuId"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Menu ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="menu.menuId"
                    required
                    type="number"
                    name="menuId"
                    id="menuId"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="env"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Environment
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="menu.env"
                    id="env"
                    name="env"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="dev">Dev</option>
                    <option value="uat">UAT</option>
                    <option value="prod">Production</option>
                  </select>
                </div>
              </div>

              <div
                :class="orgClass"
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="org"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Org
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="menu.org"
                    required
                    type="text"
                    name="org"
                    id="org"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <router-link
              to="/"
              tag="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </router-link>
            <button
              @click="saveMenu"
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { ref } from "vue";
import MenuHeader from "@/components/MenuHeader";
import { useMenuAdd } from "./hook";

export default {
  name: "MenuAdd",
  components: {
    MenuHeader,
  },
  setup() {
    const { menu, privilege, saveMenu } = useMenuAdd();

    const orgClass = ref("");
    if(privilege.value==='client'){
      orgClass.value = "invisible"
    }

    return {
      menu,
      orgClass,
      saveMenu,
    };
  },
};
</script>

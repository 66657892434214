import { ref, computed } from "vue";
import { useRouter } from 'vue-router'
import { addItem } from "@/services/items";
import store from "@/store";

export const useMenuAdd = () => {
    const router = useRouter();

    const orgs = computed(() => store.state.user.profile.orgs);
    const privilege = computed(() => store.state.user.profile.privilege);
    const menu = ref({
        name: "",
        siteId: 0,
        menuId: 0,
        env: "dev",
        org: orgs.value[0],
    });

    const saveMenu = async () => {
        store.dispatch("setLoading", true);
        try {
            await addItem({ table: 'menus', item: menu.value });
            store.dispatch("setSuccess", 'Menu added');
            router.push('/');
        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    };

    return {
        menu: computed(() => menu.value),
        privilege: computed(() => privilege.value),
        saveMenu,
    }
}